import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { H1, Paragraph, Heading } from 'components/common/Styles';

import { Content } from 'components/Content';
import Footer from 'components/Footer/Footer';
import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

const TermsAndConditions = () => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};
	return (
		<ThemeProviderComponent>
			<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
			<PaddingTopContainer isBurgerOpen={isMenuOpen}>
				<Content isOpen={isMenuOpen}>
					<TopPadding />
					<Paragraph></Paragraph>
					<H1>Terms and conditions</H1>
					<Paragraph></Paragraph>
					<Heading>Introduction</Heading>
					<Paragraph>
						The terms and conditions which govern your use of the website made available at BookPhysio.com
						(Site) are set out below.
					</Paragraph>
					<Paragraph>
						The Site is provided by Local Physio Network Pty Ltd (ABN: 26 641 519 904) (we, us and our) to
						users of the Site (you and your).
					</Paragraph>
					<Paragraph>
						Through the Site we provide an information and booking service for people who are searching for
						physiotherapy and associated services in a specific geographical area.
					</Paragraph>
					<Paragraph>
						The Site sources information about clinics and physicians which offer physiotherapy services in
						Australia (Clinics) and the services and fees offered by those Clinics.
					</Paragraph>
					<Paragraph>These terms and conditions govern the use of the Site.</Paragraph>
					<Heading>Scope of our Service</Heading>
					<Paragraph>
						All information displayed on the Site in respect of each Clinic is provided by the Clinic (and
						not by us) but may be edited by us for marketing purposes from time-to-time.
					</Paragraph>
					<Paragraph>
						While we do our best to verify the information provided by each Clinic, we are not responsible
						for the accuracy and completeness of the information in respect of the Clinic on the Site.
					</Paragraph>
					<Paragraph>
						We do not independently verify the information provided by each Clinic and we make no promise
						about the accuracy of the information provided or the benefit to be derived from using such
						information. Users need to take this into account when forming their own view on whether to
						engage the services of a Clinic.
					</Paragraph>
					<Paragraph>
						We are not responsible for any communication, interaction or relationship between you, a Clinic
						or any other users of the Site, whether or not it occurs on the Site or by another means.
					</Paragraph>
					<Heading>Use of the Site</Heading>
					<Paragraph>
						You may permit us to share information you submit to the Site with the Clinics or with people in
						connection with the Site. This is to enable you to connect with a Clinic and enjoy the full
						benefit of the Site.
					</Paragraph>
					<Paragraph>We do not charge you a fee for your use of the Site.</Paragraph>
					<Paragraph>You warrant that:</Paragraph>
					<List>
						<Item>you are at least 18 years old;</Item>
						<Item>the details you provide to us about yourself are true, accurate and complete;</Item>
						<Item>you will comply with any policies published by us on the Site.</Item>
					</List>
					<Paragraph>You must not:</Paragraph>
					<List>
						<Item>impersonate others;</Item>
						<Item>misrepresent your affiliation with others;</Item>
						<Item>
							post reviews or commentary to promulgate deceptive or offensive or extreme opinions or any
							other illegal, malicious or deceptive activities;
						</Item>
						<Item>
							interrupt or attempt to negatively impact or alter the operation of the Site in any way;
						</Item>
						<Item>
							use the Site in a way that violates applicable laws, that violates the intellectual property
							rights or other rights of us or others, or that is fraudulent, obscene, unprofessional,
							offensive, misleading or defamatory;
						</Item>
						<Item>
							reverse engineer, decompile or disassemble the Site or use the Site to develop a solution
							that is the same or substantially similar to the Site;
						</Item>
						<Item>
							licence, sell, rent, lease, transfer, assign or otherwise commercially exploit, or otherwise
							make the Site available to any third party; or
						</Item>
						<Item>
							except as permitted under this agreement, modify, reproduce, display, publish, distribute,
							copy, transmit, perform, license, create derivative works from, transfer, or sell or resell
							any information, content, software, or materials made available through the Site.
						</Item>
					</List>
					<Heading>Linking</Heading>
					<Paragraph>
						The Site may from time to time contain links to other websites. These are provided for
						convenience only.
					</Paragraph>
					<Paragraph>We have no control over or responsibility for anything on those websites.</Paragraph>
					<Paragraph>
						Any link to another website does not imply our endorsement, support, or sponsorship of the
						operator of that website nor of the information and/or products which they provide.
					</Paragraph>
					<Heading>Availability</Heading>
					<Paragraph>
						We will, to the best of our ability, ensure the Site is available for access at all times but
						are not responsible if you are not able to access the Site as a result of: (i) us undertaking
						preventative or remedial maintenance, or (ii) as a result of any circumstances beyond our
						control.
					</Paragraph>
					<Heading>Disclaimers</Heading>
					<Paragraph>
						While we take all due care in providing the Site, we do not provide any specific warranty,
						either express or implied, including without limitation warranties of merchantability or fitness
						for a particular purpose except as we are required to by the Australian Consumer Law.
					</Paragraph>
					<Paragraph>
						We take all due care in ensuring that the Site is free of any virus, worm, Trojan horse and/or
						malware, however we are not responsible for any damage to your device which arises in connection
						with your use of the Site or any linked websites.
					</Paragraph>
					<Heading>Liability and Indemnity</Heading>
					<Paragraph>
						We exclude liability for any of these things incurred by you: loss of revenue, loss of earnings,
						loss of goodwill, loss of customers, loss of capital, damage to reputation, loss in connection
						with any other contract, loss of data, or indirect, Consequential Loss or special loss, damage
						or expense.
					</Paragraph>
					<Paragraph>
						You acknowledge that some risks cannot be eliminated. You specifically assume the risk of injury
						or harm arising from use of the Site.
					</Paragraph>
					<Paragraph>
						We do not exclude or restrict our liability for any matter which it would be unlawful for us to
						exclude or attempt to exclude our liability.
					</Paragraph>
					<Paragraph>We are not responsible for:</Paragraph>
					<List>
						<Item>
							any injury (whether physical or mental) that occurs while you are using the Site; or
						</Item>
						<Item>any loss of property that occurs while you are you are using the Site.</Item>
					</List>
					<Paragraph>
						To the full extent permitted by the law, our liability for our breach of any guarantee,
						condition or warranty is limited to AUD$1.
					</Paragraph>
					<Paragraph>
						We accept no liability for any loss caused in whole, or in part, by your misuse of the Site.
					</Paragraph>
					<Paragraph>
						You indemnify us against claims, actions, proceedings, losses, damages, expenses and costs
						asserted in respect of or awarded against us in respect of your use of the Site or the act or
						omission of a Clinic.
					</Paragraph>
					<Paragraph>
						For the purposes of this clause Consequential Loss means loss of revenue, loss of earnings, loss
						of anticipated savings or business, pure economic loss, loss of data, loss of value of equipment
						(other than the cost of repair), loss of opportunity or expectation loss and any other form of
						consequential, special, indirect, punitive or exemplary loss or damages.
					</Paragraph>
					<Heading>Data</Heading>
					<Paragraph>
						Using the Site will require you to provide us information about yourself and other information
						which is not personal information (Data).
					</Paragraph>
					<Paragraph>
						You grant to us a perpetual, worldwide, non-exclusive, transferable licence to enable us to use
						the Data to provide the Site, improve the Site functions, share the Data with third-parties and
						for our own records.
					</Paragraph>
					<Paragraph>
						We may make the Data available to other companies, organisations or individuals to use on a
						de-identifiable basis. No compensation will be paid to you with respect to the Data that you
						submit on the Site. You should only submit Data on the Site that you are comfortable sharing
						with others in accordance with these terms and conditions.
					</Paragraph>
					<Heading>Privacy</Heading>
					<Paragraph>
						In order to use the Site, you must provide us with certain information about yourself. To the
						extent that this information is Personal Information (as defined in the Privacy Act 1988 (Cth))
						we will handle and store this information in accordance with our{' '}
						<Link to='/privacy-policy'>Privacy Policy</Link>
					</Paragraph>
					<Heading>Intellectual Property</Heading>
					<Paragraph>
						You acknowledge that any intellectual property rights (including graphics, logos, design, or
						icons) relating to or appearing on the Site remain the property of us and/or our licensors.
					</Paragraph>
					<Heading>Termination</Heading>
					<Paragraph>
						We reserve the right to suspend or terminate your right to use the Site if, at any time, you
						breach these terms and conditions.
					</Paragraph>
					<Heading>Monitoring use of the Site</Heading>
					<Paragraph>
						We reserve the right to monitor your use of the Site, through cookies and other means, for the
						purpose of:
					</Paragraph>
					<List>
						<Item>
							enabling us to understand the way users navigate the Site and use the services available on
							it;
						</Item>
						<Item>improve the experience of users using the Site;</Item>
						<Item>ensuring that the Site is functioning as we intended; and</Item>
						<Item>ensuring that users are complying with these terms and conditions.</Item>
					</List>
					<Heading>Feedback</Heading>
					<Paragraph>
						We may contact you, by email or the phone number you provided, to obtain your feedback on your
						experience using our Site or a Clinic.
					</Paragraph>
					<Paragraph>
						If you wish to make any comments about the Site or your experience with a Clinic, we encourage
						you to provide us feedback by contacting us by email at
						<HoveredInline>
							<a href='mailto:team@bookphysio.com'>team@bookphysio.com</a>.
						</HoveredInline>
					</Paragraph>
					<Heading>Updates to the Terms and Conditions</Heading>
					<Paragraph>
						We may change these terms at any time. You should also keep track of whether changes have been
						made to these terms and conditions by referring to the date of publication at the end of this
						page. If you access the Site following any amendment to these terms and conditions you will be
						taken to have agreed to comply with the terms and conditions as changed. If you do not agree
						with changes to the terms and conditions, you can choose to no longer access the Site.
					</Paragraph>
					<Heading>Notices</Heading>
					<Paragraph>
						Any notice we send to you will be emailed to the email address that you provide to us when
						creating an account on the Site.
					</Paragraph>
					<Heading>General</Heading>
					<Paragraph>
						If any provision in these terms and conditions is invalid under any law, the provision will be
						limited, narrowed, construed or altered as necessary to render it valid but only to the extent
						necessary to achieve such validity. If necessary, the invalid provision will be deleted from
						these terms and conditions and the remaining provisions will remain in full force and effect.
					</Paragraph>
					<Paragraph>
						These terms and conditions are to be governed by and construed in accordance with the laws of
						New South Wales and any claim made by either party against the other which in any way arises out
						of these terms and conditions will be heard in New South Wales. You agree to submit to the
						jurisdiction of the courts in New South Wales.
					</Paragraph>
					<Paragraph>Published: 24 December 2021</Paragraph>
				</Content>
				<Footer />
			</PaddingTopContainer>
		</ThemeProviderComponent>
	);
};

const TopPadding = styled.div`
	width: 100%;
	height: 50px;

	@media (max-width: 767px) {
		display: none;
	}
`;
const List = styled.ul`
	font-weight: 300;
	color: #333e48;
	margin: 0 0 15px;
`;
const Item = styled.li`
	font-weight: 300;
	margin: 0 0 15px 40px;
`;
const Hovered = styled(Paragraph)`
	& a {
		color: ${({ theme }) => theme.palette.purp};
		transition: all 0.3s ease;
		cursor: pointer;
		&:hover {
			color: ${({ theme }) => theme.palette.main};
			text-decoration: none;
		}
	}
`;

const HoveredInline = styled(Hovered)`
	display: inline-block;
	margin: 0 5px;
`;
export default TermsAndConditions;
